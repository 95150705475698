(function ($) {
    $(function() {
        $(".banners-one-by-one > ul > li").on('click', function(e) {
            e.preventDefault();
            let i = $(this).data('item');
            $(this).addClass('active').siblings().removeClass('active');
            $("#addition_item" + i).addClass('active').siblings().removeClass('active');
        });
        $(".ets_mm_block_content .has-sub > a .after-element").on('click', function(e) {
            e.preventDefault();
        });
    });
})(jQuery);
